import { default as course_45guidancerF9WXfJv9QMeta } from "/vercel/path0/pages/course-guidance.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as module_451fLtRtZC9ejMeta } from "/vercel/path0/pages/module-1.vue?macro=true";
import { default as module_452etz9QjCTJ4Meta } from "/vercel/path0/pages/module-2.vue?macro=true";
import { default as module_4535nWYC9lRKtMeta } from "/vercel/path0/pages/module-3.vue?macro=true";
import { default as module_454GZRbaiQAiHMeta } from "/vercel/path0/pages/module-4.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as demographicsCJbbPBlhKSMeta } from "/vercel/path0/pages/report/demographics.vue?macro=true";
import { default as durationsqCBIkhBjR0Meta } from "/vercel/path0/pages/report/durations.vue?macro=true";
import { default as indexAzUOXnxcxeMeta } from "/vercel/path0/pages/report/index.vue?macro=true";
import { default as module_451eAjKLTH2PYMeta } from "/vercel/path0/pages/report/reflections/module-1.vue?macro=true";
import { default as module_4524SE2595FDjMeta } from "/vercel/path0/pages/report/reflections/module-2.vue?macro=true";
import { default as module_453fJIKeFQKwaMeta } from "/vercel/path0/pages/report/reflections/module-3.vue?macro=true";
import { default as module_4549TOeB9wCKoMeta } from "/vercel/path0/pages/report/reflections/module-4.vue?macro=true";
import { default as understandingvWjNTiRTWxMeta } from "/vercel/path0/pages/report/understanding.vue?macro=true";
import { default as videosOJPorQaIblMeta } from "/vercel/path0/pages/report/videos.vue?macro=true";
import { default as reportPTiE8a2T5tMeta } from "/vercel/path0/pages/report.vue?macro=true";
import { default as resourcesYHmsQM8McLMeta } from "/vercel/path0/pages/resources.vue?macro=true";
export default [
  {
    name: course_45guidancerF9WXfJv9QMeta?.name ?? "course-guidance",
    path: course_45guidancerF9WXfJv9QMeta?.path ?? "/course-guidance",
    meta: course_45guidancerF9WXfJv9QMeta || {},
    alias: course_45guidancerF9WXfJv9QMeta?.alias || [],
    redirect: course_45guidancerF9WXfJv9QMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/course-guidance.vue").then(m => m.default || m)
  },
  {
    name: dashboardHdtnClvoGAMeta?.name ?? "dashboard",
    path: dashboardHdtnClvoGAMeta?.path ?? "/dashboard",
    meta: dashboardHdtnClvoGAMeta || {},
    alias: dashboardHdtnClvoGAMeta?.alias || [],
    redirect: dashboardHdtnClvoGAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: module_451fLtRtZC9ejMeta?.name ?? "module-1",
    path: module_451fLtRtZC9ejMeta?.path ?? "/module-1",
    meta: module_451fLtRtZC9ejMeta || {},
    alias: module_451fLtRtZC9ejMeta?.alias || [],
    redirect: module_451fLtRtZC9ejMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/module-1.vue").then(m => m.default || m)
  },
  {
    name: module_452etz9QjCTJ4Meta?.name ?? "module-2",
    path: module_452etz9QjCTJ4Meta?.path ?? "/module-2",
    meta: module_452etz9QjCTJ4Meta || {},
    alias: module_452etz9QjCTJ4Meta?.alias || [],
    redirect: module_452etz9QjCTJ4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/module-2.vue").then(m => m.default || m)
  },
  {
    name: module_4535nWYC9lRKtMeta?.name ?? "module-3",
    path: module_4535nWYC9lRKtMeta?.path ?? "/module-3",
    meta: module_4535nWYC9lRKtMeta || {},
    alias: module_4535nWYC9lRKtMeta?.alias || [],
    redirect: module_4535nWYC9lRKtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/module-3.vue").then(m => m.default || m)
  },
  {
    name: module_454GZRbaiQAiHMeta?.name ?? "module-4",
    path: module_454GZRbaiQAiHMeta?.path ?? "/module-4",
    meta: module_454GZRbaiQAiHMeta || {},
    alias: module_454GZRbaiQAiHMeta?.alias || [],
    redirect: module_454GZRbaiQAiHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/module-4.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQl0qlywOJTMeta?.name ?? "privacy-policy",
    path: privacy_45policyQl0qlywOJTMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyQl0qlywOJTMeta || {},
    alias: privacy_45policyQl0qlywOJTMeta?.alias || [],
    redirect: privacy_45policyQl0qlywOJTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    path: reportPTiE8a2T5tMeta?.path ?? "/report",
    children: [
  {
    name: demographicsCJbbPBlhKSMeta?.name ?? "report-demographics",
    path: demographicsCJbbPBlhKSMeta?.path ?? "demographics",
    meta: demographicsCJbbPBlhKSMeta || {},
    alias: demographicsCJbbPBlhKSMeta?.alias || [],
    redirect: demographicsCJbbPBlhKSMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/demographics.vue").then(m => m.default || m)
  },
  {
    name: durationsqCBIkhBjR0Meta?.name ?? "report-durations",
    path: durationsqCBIkhBjR0Meta?.path ?? "durations",
    meta: durationsqCBIkhBjR0Meta || {},
    alias: durationsqCBIkhBjR0Meta?.alias || [],
    redirect: durationsqCBIkhBjR0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/durations.vue").then(m => m.default || m)
  },
  {
    name: indexAzUOXnxcxeMeta?.name ?? "report",
    path: indexAzUOXnxcxeMeta?.path ?? "",
    meta: indexAzUOXnxcxeMeta || {},
    alias: indexAzUOXnxcxeMeta?.alias || [],
    redirect: indexAzUOXnxcxeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/index.vue").then(m => m.default || m)
  },
  {
    name: module_451eAjKLTH2PYMeta?.name ?? "report-reflections-module-1",
    path: module_451eAjKLTH2PYMeta?.path ?? "reflections/module-1",
    meta: module_451eAjKLTH2PYMeta || {},
    alias: module_451eAjKLTH2PYMeta?.alias || [],
    redirect: module_451eAjKLTH2PYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/reflections/module-1.vue").then(m => m.default || m)
  },
  {
    name: module_4524SE2595FDjMeta?.name ?? "report-reflections-module-2",
    path: module_4524SE2595FDjMeta?.path ?? "reflections/module-2",
    meta: module_4524SE2595FDjMeta || {},
    alias: module_4524SE2595FDjMeta?.alias || [],
    redirect: module_4524SE2595FDjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/reflections/module-2.vue").then(m => m.default || m)
  },
  {
    name: module_453fJIKeFQKwaMeta?.name ?? "report-reflections-module-3",
    path: module_453fJIKeFQKwaMeta?.path ?? "reflections/module-3",
    meta: module_453fJIKeFQKwaMeta || {},
    alias: module_453fJIKeFQKwaMeta?.alias || [],
    redirect: module_453fJIKeFQKwaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/reflections/module-3.vue").then(m => m.default || m)
  },
  {
    name: module_4549TOeB9wCKoMeta?.name ?? "report-reflections-module-4",
    path: module_4549TOeB9wCKoMeta?.path ?? "reflections/module-4",
    meta: module_4549TOeB9wCKoMeta || {},
    alias: module_4549TOeB9wCKoMeta?.alias || [],
    redirect: module_4549TOeB9wCKoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/reflections/module-4.vue").then(m => m.default || m)
  },
  {
    name: understandingvWjNTiRTWxMeta?.name ?? "report-understanding",
    path: understandingvWjNTiRTWxMeta?.path ?? "understanding",
    meta: understandingvWjNTiRTWxMeta || {},
    alias: understandingvWjNTiRTWxMeta?.alias || [],
    redirect: understandingvWjNTiRTWxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/understanding.vue").then(m => m.default || m)
  },
  {
    name: videosOJPorQaIblMeta?.name ?? "report-videos",
    path: videosOJPorQaIblMeta?.path ?? "videos",
    meta: videosOJPorQaIblMeta || {},
    alias: videosOJPorQaIblMeta?.alias || [],
    redirect: videosOJPorQaIblMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/videos.vue").then(m => m.default || m)
  }
],
    name: reportPTiE8a2T5tMeta?.name ?? undefined,
    meta: reportPTiE8a2T5tMeta || {},
    alias: reportPTiE8a2T5tMeta?.alias || [],
    redirect: reportPTiE8a2T5tMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report.vue").then(m => m.default || m)
  },
  {
    name: resourcesYHmsQM8McLMeta?.name ?? "resources",
    path: resourcesYHmsQM8McLMeta?.path ?? "/resources",
    meta: resourcesYHmsQM8McLMeta || {},
    alias: resourcesYHmsQM8McLMeta?.alias || [],
    redirect: resourcesYHmsQM8McLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/resources.vue").then(m => m.default || m)
  }
]