export default defineNuxtRouteMiddleware( async( to, from ) => {
    const user = useSupabaseUser();
    const config = useRuntimeConfig();

    let emailCheck = false;

    if( user.value ) {
        let emailAddress = user.value.email.split('@').pop()

        if( emailAddress == '21six.com' || emailAddress == 'canyouthinkbeyond.com' || emailAddress == 'vfc.com' ) {
            emailCheck = true;
        }
    }

    if( config.public.status != 'closed' ) {
        if( to.path.includes("/report") && ( !user.value || !emailCheck )){
            return navigateTo('/');
        } else if( to.path == "/report/reflections" && user.value && emailCheck ){
            return navigateTo('/report/reflections/module-1');
        }
    } else if (to.path !== "/" ) {
        return navigateTo('/');
    }
});