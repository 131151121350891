export function useModuleProgress() {
    const store = useTNFStore();

    function canUserProgress(page) {
        let proceed = false;

        switch(page) {
            case "module-1":
                if( store.progress.percentage >= 0 ) proceed = true;
                break;
            case "module-2":
                if( store.progress.percentage >= 25 ) proceed = true;
                break;
            case "module-3":
                if( store.progress.percentage >= 50 ) proceed = true;
                break;
            case "module-4":
                if( store.progress.percentage >= 75 ) proceed = true;
                break;
            default:
                return false;
        }

        return proceed;
    }

    const updateModuleProgress = async (percentage) => {

        if( parseInt(store.progress.percentage) > parseInt(percentage) ) {
            return true;
        }

        store.progress.percentage = percentage;

        if( store.progress.percentage < 25 ) {
            store.progress.current_module = '1';
            store.progress.module_1 = 'In Progress';
        }

        if( store.progress.percentage >= 25 && store.progress.percentage < 50 ) {
            store.progress.current_module = '2';
            store.progress.module_1 = 'Completed';
            store.progress.module_2 = 'In Progress';
        }

        if( store.progress.percentage >= 50 && store.progress.percentage < 75 ) {
            store.progress.current_module = '3';
            store.progress.module_2 = 'Completed';
            store.progress.module_3 = 'In Progress';
        }

        if( store.progress.percentage >= 75 && store.progress.percentage < 100 ) {
            store.progress.current_module = '4';
            store.progress.module_3 = 'Completed';
            store.progress.module_4 = 'In Progress';
        }

        if( store.progress.percentage >= 100 ) {
            store.progress.current_module = '4';
            store.progress.module_4 = 'Completed';
        }

        if( store.user ) {
            try {
                const { data, error } = await useFetch( '/api/progress/upsert', {
                    params: {
                        'user'                      : store.user.id,
                        'percentage'                : store.progress.percentage,
                        'current_module'            : store.progress.current_module,
                        'module_1'                  : store.progress.module_1,
                        'module_2'                  : store.progress.module_2,
                        'module_3'                  : store.progress.module_3,
                        'module_4'                  : store.progress.module_4,
                        'completed_profile'         : store.progress.completed_profile,
                        'submitted_feedback'        : store.progress.submitted_feedback,
                        'downloaded_certificate'    : store.progress.downloaded_certificate,
                        'shared_certificate'        : store.progress.shared_certificate,
                    },
                });
    
                if( error.value ) throw error;
        
            } catch (error) {
                console.error(error)
            }
    
            return true;
        }
    }


    const storeModuleAnswer = async (params) => {

        if( store.user ) {
            try {

                const { data, error } = await useFetch( '/api/answers/upsert', {
                    params: {
                        'user'      : store.user.id,
                        'module'    : params.module,
                        'question'  : params.question,
                        'answer'    : params.answer,
                        'correct'   : params.answeredCorrect
                    },
                });

                if( data ) {
                    let answerID = data.value.id;

                    try {

                        const { data, error } = await useFetch( '/api/module-' + params.module + '/upsert', {
                            params: {
                                'user'      : store.user.id,
                                'question'  : params.question,
                                'answer_id' : answerID
                            },
                        });

                        if( error.value ) throw error;
                
                    } catch (error) {
                        return error;
                    }
                }

                if( error.value ) throw error;
        
            } catch (error) {
                return error;
            }
        }

        return true;

    }

    const storeModuleOneReflection = async (reflections) => {

        store.module_1.reflection.inclusive = reflections.inclusive;
        store.module_1.reflection.lived_experiences = reflections.lived_experiences;
        store.module_1.reflection.comments = reflections.comments;

        if( store.user ) {
            try {
                    
                const { data, error } = await useFetch( '/api/module-1/upsert', {
                    params: {
                        'user'              : store.user.id,
                        'reflection'        : true,
                        'inclusive'         : store.module_1.reflection.inclusive,
                        'lived_experiences' : store.module_1.reflection.lived_experiences,
                        'comments'          : store.module_1.reflection.comments,
                    },
                });
                
                if( error.value ) throw error;

                if( store.module_1.reflection.inclusive == null || store.module_1.reflection.lived_experiences == null ) {
                    return false;
                }
        
            } catch (error) {
                return error;
            }
        }

        return true;

    }

    const storeModuleTwoReflection = async (reflections) => {

        store.module_2.reflection.sense_of_belonging = reflections.sense_of_belonging;
        store.module_2.reflection.excluded_left_out = reflections.excluded_left_out;
        store.module_2.reflection.your_role = reflections.your_role;
        store.module_2.reflection.comments = reflections.comments;

        if( store.user ) {
            try {

                const { data, error } = await useFetch( '/api/module-2/upsert', {
                    params: {
                        'user'                  : store.user.id,
                        'reflection'            : true,
                        'sense_of_belonging'    : store.module_2.reflection.sense_of_belonging,
                        'excluded_left_out'     : store.module_2.reflection.excluded_left_out,
                        'your_role'             : store.module_2.reflection.your_role,
                        'comments'              : store.module_2.reflection.comments,
                    },
                });
                
                if( error.value ) throw error;

                if( store.module_2.reflection.sense_of_belonging == null || store.module_2.reflection.excluded_left_out == null || store.module_2.reflection.your_role == null ) {
                    return false;
                }
        
            } catch (error) {
                return error;
            }
        }

        return true;

    }

    const storeModuleThreeSelfAssessment = async (params) => {

        store.module_3.self_assessment[params.question] = params.answer;

        if( store.user ) {
            try {

                const { data, error } = await useFetch( '/api/module-3/upsert', {
                    params: {
                        'user'              : store.user.id,
                        'self_assessment'   : true,
                        'question'          : params.question,
                        'answer'            : store.module_3.self_assessment[params.question],
                    },
                });
                
                if( error.value ) throw error;

                if( store.module_3.self_assessment[params.question] == null || store.module_3.self_assessment[params.question].length == 0 ) {
                    return false;
                }
    
            } catch (error) {
                return error;
            }
        }

        return true;

    }

    const storeModuleFourPledge = async (pledge) => {

        store.module_4.pledge.your_pledge = pledge.your_pledge;

        if( store.user ) {
            try {

                const { data, error } = await useFetch( '/api/module-4/upsert', {
                    params: {
                        'user'          : store.user.id,
                        'your_pledge'   : store.module_4.pledge.your_pledge,
                    },
                });
                
                if( error.value ) throw error;
        
            } catch (error) {
                return error;
            }
        }

        return true;

    }

    const hasCompletedModuleOne = () => {
        
        if( store.progress.percentage >= 25 ) {
            return true;
        }

        return false;
    }

    const hasCompletedModuleTwo = () => {
        
        if( store.progress.percentage >= 50 ) {
            return true;
        }

        return false;
    }

    const hasCompletedModuleThree = () => {
        
        if( store.progress.percentage >= 75 ) {
            return true;
        }

        return false;
    }

    const hasCompletedModuleFour = () => {
        
        if( store.progress.percentage >= 100 ) {
            return true;
        }

        return false;
    }

    return {
        canUserProgress,
        updateModuleProgress,
        storeModuleAnswer,
        storeModuleOneReflection,
        storeModuleTwoReflection,
        storeModuleThreeSelfAssessment,
        storeModuleFourPledge,
        hasCompletedModuleOne,
        hasCompletedModuleTwo,
        hasCompletedModuleThree,
        hasCompletedModuleFour
    };
}
  

