export default defineNuxtRouteMiddleware( async( to, from ) => {
    const config = useRuntimeConfig();
    const user = useSupabaseUser();
    const store = useTNFStore();

    if( config.public.status != 'closed' ) {
        if( !user.value && ( to.path !== "/" && to.path !== "/privacy-policy" ) ){
            return navigateTo('/')
        } else if( user.value && !store.progress.completed_profile && ( to.path !== "/" && to.path !== "/privacy-policy" )  ){
            return navigateTo('/')
        }
    } else if (to.path !== "/" ) {
        return navigateTo('/');
    }


})