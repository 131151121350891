<template>
    <div class="locked">
        <SVGIconClose @click="closeLoadingScreen"/>
        <SVGLocked />
        <p v-html="props.message"></p>
    </div>
</template>

<style lang="scss">
    @import "@sass-components/locked.scss";
</style>

<script setup>
    import { gsap } from "gsap";

    const props = defineProps(["message"]);
    const emits = defineEmits(["closeLoadingScreen"]);

    onMounted(() => {
        gsap.set(".locked", { opacity: 0 } );

        var tl = gsap.timeline();
            tl.to(".locked", { duration: 0.5, opacity: 1 } );
    });

    const closeLoadingScreen = () => {
        emits("closeLoadingScreen");
    }
</script>