export const useInitStore = () => {
    return {
        loading: true,
        user: null,
        login: {
            id: null,
            email: null,
            status: false
        },
        progress: {
            current_module: '1',
            percentage: '0',
            module_1: 'Not Started',
            module_2: 'Not Started',
            module_3: 'Not Started',
            module_4: 'Not Started',
            completed_profile: false,
            submitted_feedback: false,
            downloaded_certificate: false,
            shared_certificate: false,
            voucher: null
        },
        module_1: {
            questions: {
                statements: {
                    answer: [],
                    correct: false
                },
                equality: {
                    answer: [],
                    correct: false
                },
                safe_and_welcome: {
                    answer: [],
                    correct: false
                }
            },
            reflection: {
                inclusive: null,
                lived_experiences: null,
                comments: null,
            },
        },
        module_2: {
            questions: {
                etb_model: {
                    answer: [],
                    correct: false
                },
                lived_experiences: {
                    answer: [],
                    correct: false
                }
            },
            reflection: {
                sense_of_belonging: null,
                excluded_left_out: null,
                your_role: null,
                comments: null,
            },
        },
        module_3: {
            questions: {
                scenario_1: {
                    answer: [],
                    correct: false
                },
                scenario_2: {
                    answer: [],
                    correct: false
                },
                scenario_3: {
                    answer: [],
                    correct: false
                },
                scenario_4: {
                    answer: [],
                    correct: false
                },
                scenario_5: {
                    answer: [],
                    correct: false
                },
                level_of_racism: {
                    answer: [],
                    correct: false
                },
                internalised_racism: {
                    answer: [],
                    correct: false
                }
            },
            self_assessment: {
                personal_feelings: [],
            },
        },
        module_4: {
            questions: {
                personal_or_systemic: {
                    answer: [],
                    correct: false
                },
                what_is_allyship: {
                    answer: [],
                    correct: false
                },
                allyship_become_harmful: {
                    answer: [],
                    correct: false
                }
            },
            pledge: {
                your_pledge: null,
            },
        }
    }
}