import error404 from "@data/404.json";
import welcome from "@data/welcome.json";
import menu from "@data/menu.json";
import dashboard from "@data/dashboard.json";
import feedbackContent from "@data/feedback.json";
import moduleOneContent from "@data/module_one.json";
import moduleTwoContent from "@data/module_two.json";
import moduleThreeContent from "@data/module_three.json";
import moduleFourContent from "@data/module_four.json";
import complete from "@data/complete.json";
import courseFeedbackContent from "@data/course_feedback.json";
import glossary from "@data/glossary.json";
import resources from "@data/resources.json";
import courseGuidance from "@data/course_guidance.json";
import privacyPolicy from "@data/privacy_policy.json";

export const useGetData = () => {
    return {
        error404,
        welcome,
        dashboard,
        menu,
        feedbackContent,
        moduleOneContent,
        moduleTwoContent,
        moduleThreeContent,
        moduleFourContent,
        complete,
        courseFeedbackContent,
        glossary,
        resources,
        courseGuidance,
        privacyPolicy
    };
};
