export function useResponsive() {
    const isMobile = useMediaQuery("(max-width: 767px) and (orientation: portrait)")
    const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)")
    const isPortable = useMediaQuery("(max-width: 1024px) and (orientation: portrait)")
    const isSmallDesktop = useMediaQuery("(max-height: 800px) and (max-width: 1024px) and (orientation: landscape)")
    const isShallowDesktop = useMediaQuery("(max-height: 600px) and (orientation: landscape)")
    const isSmallerThanXL = useMediaQuery("(max-width: 1279px)")

    return {
        isMobile,
        isTablet,
        isPortable,
        isSmallDesktop,
        isShallowDesktop,
        isSmallerThanXL
    };
}
  

