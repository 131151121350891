<template>
    <div class="tnf-allyship">
        <NuxtLayout name="404">
            <TitleBlock :content="content.error404">
                <nuxt-link class="button" :to="content.error404.continue_course_button.link">
                    <span v-html="content.error404.continue_course_button.text"></span>
                </nuxt-link>
            </TitleBlock>
        </NuxtLayout>
        <SVGMountain />
    </div>
</template>

<script setup>
    const content = useGetData();
</script>