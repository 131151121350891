import * as Sentry from '@sentry/browser';

export default defineNuxtPlugin(() => {
	Sentry.init({
		dsn: 'https://479a3b69ef6e691e005868a29d51140f@o4505743623323648.ingest.sentry.io/4505743627124736',
		sampleRate: 0.1,
		tracesSampleRate: 1,
		integrations: [new Sentry.Replay()],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1
	})
})