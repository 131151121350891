import { useInitStore } from './default'

export const useTNFStore = defineStore('tnfAllyship', {
    state: () => {
        return useInitStore()
    },
    actions: {
        loginUser(user) {
            this.user = user.value;
        },
        logoutUser() {
            const sessionCookie = useCookie('tnfAllyship');
            sessionCookie.value = useInitStore();
        },
        resetUserProgress() {
            let defaultStore = useInitStore();
            this.progress = defaultStore.progress;
        }
    },
    persist: true
});

if( import.meta.hot ) {
    import.meta.hot.accept(acceptHMRUpdate(useTNFStore, import.meta.hot));
}