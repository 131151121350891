export default defineNuxtRouteMiddleware( async( to, from ) => {
    const config = useRuntimeConfig();
    const user = useSupabaseUser();
    const store = useTNFStore();
    const { canUserProgress } = useModuleProgress();

    let modulePaths = ["module-1", "module-2", "module-3", "module-4"]

    if( config.public.status != 'closed' ) {
        if( user.value && !canUserProgress( to.name ) && modulePaths.includes( to.name ) ){
            return navigateTo('/dashboard');
        }
    } else if (to.path !== "/" ) {
        return navigateTo('/');
    }
});