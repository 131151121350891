<template>
    <Locked v-if="restrictedContent" :message="restrictedContentMessage" @closeLoadingScreen="hideLockedScreen" />
    <div class="mountain-background">
        <svg width="1440" height="1024" viewBox="0 0 1440 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="cloud-back-mask" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="1080" y="578" width="181" height="48">
                <path d="M1261 578.613H1080V625.676H1261V578.613Z" fill="white"/>
            </mask>
            <g id="cloud-back" mask="url(#cloud-back-mask)">
                <path d="M1258.92 616.744C1250.7 605.1 1236.39 611.159 1236.39 611.159C1230.14 600.151 1218.63 604.778 1218.63 604.778C1218.63 604.778 1209.09 578.935 1185.41 578.617C1161.73 578.3 1154.49 596.643 1154.49 596.643C1148.1 596.037 1142.91 596.613 1138.74 597.812C1139.51 600.553 1144.61 613.655 1170.74 605.846C1170.74 605.846 1181.55 600.435 1191.52 609.782C1191.52 609.782 1195.07 615.685 1211.81 615.028C1226.52 614.451 1231.95 623.379 1233.05 625.54C1249.77 625.527 1260.9 625.515 1260.9 625.515C1261.55 620.409 1258.92 616.74 1258.92 616.74V616.744Z" fill="#ECEEFB"/>
                <path d="M1211.81 615.028C1195.08 615.684 1191.52 609.782 1191.52 609.782C1181.55 600.439 1170.74 605.846 1170.74 605.846C1144.62 613.651 1139.51 600.549 1138.74 597.812C1126.49 601.329 1122.92 610.202 1122.92 610.202C1115.52 600.791 1106.14 607.969 1106.14 607.969C1099.4 606.053 1096.94 606.532 1096.94 606.532C1080.33 608.13 1080 625.676 1080 625.676C1079.88 625.676 1182.28 625.587 1233.04 625.54C1231.95 623.375 1226.51 614.451 1211.8 615.028H1211.81Z" fill="#DFE5F8"/>
            </g>
        </svg>
    </div>
    <div class="mountain-main">
        <svg width="783" height="449" viewBox="0 0 783 449" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="mountain-main">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M782.5 449L650.34 206.817L566.44 132.935L519.15 61.1029L460.62 21.3689L386.57 82.0259L360.507 130.828L332.256 124.554L305.813 165.344L241.308 215.197L172.916 284.918L150.313 326.028L107.29 346.946L91.0859 374.142L0.5 449H782.5Z" fill="#E0E5E5"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M229 449L257.353 398.016L280.65 367.341L294.723 337.354L319.404 315.065L285.557 320.65L272.87 345.052L224.199 362.444L215.029 382.678L169 449H229Z" fill="#E9F1F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M782.5 449L650.34 206.817L566.44 132.935L519.15 61.103L462.27 22.4769L490.58 58.681L483.22 59.364L481.78 83.0819L492.39 100.842L486.74 134.307L474.05 148.956L480.72 159.076L454.97 219.732L480.4 237.12L458.17 311.374L461.69 319.072L445.48 347.635L444.79 362.284L406.5 428.5L479.5 440L600 407.5L751 449H782.5Z" fill="#84888F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M605.19 339.254L518.09 341.361L553 310.691L562.17 281.76L566.06 257.732L596.72 239.604L593.57 202.976L577.68 197.759L564.3 179.631L509.29 167.089L561.11 163.61L575.61 175.097L570.65 150.695L544.9 132.935L526.57 131.512L547.36 125.926L566.44 132.935L650.35 206.822L709.31 314.697L605.2 339.254H605.19ZM508.22 86.188L539.62 92.249L547.3 103.844L508.22 86.188Z" fill="#7E7E85"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M360.507 130.828L388.34 93.2019L415.84 82.7139L416.21 101.525L444.42 63.5309L452.14 75.0688L463.82 55.8849L475.07 70.8549L481.78 83.0818L483.22 59.3639L490.58 58.6799L460.62 21.3689L386.57 82.0259L360.507 130.828Z" fill="#E9F1F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M491.64 104.952L444.79 123.504L423.63 155.597L377.78 172.985L341.796 239.231L322.763 262.944L324.148 283.867L341.103 257.359L374.96 232.274L396.12 198.809L434.87 177.887L451.82 153.485L474.05 148.956L486.74 134.307L491.64 104.952Z" fill="#E9F1F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M454.97 219.732L435.78 251.613L407.58 280.865L387.8 337.354L331.399 377.093L324.365 393.114L350.432 384.05H378.63L407.58 352.692L436.47 338.043L458.17 311.374L480.4 237.12L454.97 219.732Z" fill="#E9F1F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M324.365 190.274L285.558 206.978L255.968 264.844L196.688 334.564L262.314 296.937L280.65 260.677L308.848 232.062L318.019 208.401L344.086 186.795L324.365 190.274Z" fill="#E9F1F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M319.404 315.065L314.661 335.46L324.524 348.685L301.973 368.185L306.881 388.424L289 449H229L257.354 398.016L280.65 367.341L294.723 337.354L319.404 315.065Z" fill="#83878E"/>
                <path d="M463.51 0.60791H465.48V25.437H463.51V0.60791Z" fill="black"/>
                <path d="M440 7.37994L464.01 1.89795V12.861L440 7.37994Z" fill="#B60B31"/>
            </g>

            <mask id="module-1-pin-mask" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="359" y="263" width="23" height="33">
                <path d="M381.26 263H359.958V295.085H381.26V263Z" fill="white"/>
            </mask>

            <g id="module-1-pin" @click="goToModule(1)">
                <path id="module-1-losange" d="M367.11 268.793H323.798C319.491 268.793 316 272.284 316 276.591C316 280.898 319.491 284.39 323.798 284.39H367.11C371.42 284.39 374.91 280.898 374.91 276.591C374.91 272.284 371.42 268.793 367.11 268.793Z" fill="black"/>
                <path id="module-1-label" d="M322.371 279.093V273.738H324.028L325.281 277.421H325.296L326.481 273.738H328.138V279.093H327.036V275.298H327.021L325.708 279.093H324.801L323.488 275.336H323.473V279.093H322.371ZM328.755 276.438C328.755 274.848 329.73 273.611 331.388 273.611C333.045 273.611 334.02 274.848 334.02 276.438C334.02 277.983 333.045 279.221 331.388 279.221C329.73 279.221 328.755 277.983 328.755 276.438ZM329.933 276.438C329.933 277.331 330.33 278.231 331.388 278.231C332.445 278.231 332.843 277.331 332.843 276.438C332.843 275.501 332.445 274.601 331.388 274.601C330.33 274.601 329.933 275.501 329.933 276.438ZM334.637 279.093V273.738H336.947C338.335 273.738 339.377 274.608 339.377 276.386C339.377 277.938 338.582 279.093 336.947 279.093H334.637ZM335.815 274.728V278.103H336.865C337.547 278.103 338.2 277.683 338.2 276.498C338.2 275.418 337.825 274.728 336.655 274.728H335.815ZM344.554 273.738V277.068C344.554 278.516 343.699 279.221 342.266 279.221C340.826 279.221 339.986 278.523 339.986 277.068V273.738H341.164V277.068C341.164 277.653 341.314 278.231 342.266 278.231C343.106 278.231 343.376 277.863 343.376 277.068V273.738H344.554ZM345.381 279.093V273.738H346.558V278.103H349.168V279.093H345.381ZM349.639 279.093V273.738H353.644V274.728H350.816V275.876H353.411V276.791H350.816V278.103H353.704V279.093H349.639Z" fill="white"/>
                <g id="module-1-map-pin" mask="url(#module-1-pin-mask)">
                    <path d="M370.61 295.085C368.45 292.546 359.958 280.088 359.958 273.471C359.958 267.688 364.73 263 370.61 263" fill="#FAB446"/>
                    <path d="M370.61 295.085C372.77 292.546 381.26 280.088 381.26 273.471C381.26 267.688 376.49 263 370.61 263" fill="#FAB446"/>
                    <path d="M370.61 280.088C374.14 280.088 377.01 277.27 377.01 273.794C377.01 270.318 374.14 267.5 370.61 267.5C367.07 267.5 364.21 270.318 364.21 273.794C364.21 277.27 367.07 280.088 370.61 280.088Z" fill="white"/>
                    <path d="M371.77 270.093V277.093H370.35V272.553H368.59V271.483C369.58 271.503 370.49 271.163 370.64 270.093H371.77Z" fill="black"/>
                </g>
                <ellipse id="module-1-map-dot" cx="371" cy="274" rx="5" ry="5" fill="#B60B31"/>
            </g>
            <path id="module-1-path" d="M431 363.634L429.11 364.979L426.34 361.056L428.23 359.711L431 363.634ZM425.44 355.788L423.55 357.134L420.78 353.211L422.67 351.865L425.44 355.788ZM419.89 347.942L418 349.288L415.22 345.365L417.11 344.019L419.89 347.942ZM414.33 340.097L412.44 341.442L409.66 337.519L411.56 336.174L414.33 340.097ZM408.77 332.251L406.88 333.597L404.11 329.674L406 328.328L408.77 332.251ZM403.22 324.406L401.32 325.751L398.55 321.828L400.44 320.483L403.22 324.406ZM397.66 316.56L395.77 317.906L392.99 313.983L394.88 312.637L397.66 316.56ZM392.1 308.715L390.21 310.06L387.43 306.137L389.33 304.792L392.1 308.715ZM386.54 300.869L384.65 302.215L381.88 298.292L383.77 296.946L386.54 300.869ZM380.99 293.024L379.09 294.369L376.32 290.446L378.21 289.101L380.99 293.024Z" fill="#C10000"/>


            <mask id="module-2-pin-mask" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="435" y="211" width="23" height="33">
                <path d="M457.26 211H435.96V243.085H457.26V211Z" fill="white"/>
            </mask>

            <g id="module-2-pin" @click="goToModule(2)">
                <path id="module-2-losange" d="M443.11 216.793H399.8C395.49 216.793 392 220.284 392 224.591C392 228.898 395.49 232.39 399.8 232.39H443.11C447.42 232.39 450.91 228.898 450.91 224.591C450.91 220.284 447.42 216.793 443.11 216.793Z" fill="black"/>
                <path id="module-2-label" d="M398.37 227.093V221.738H400.03L401.28 225.421H401.3L402.48 221.738H404.14V227.093H403.04V223.298H403.02L401.71 227.093H400.8L399.49 223.336H399.47V227.093H398.37ZM404.76 224.438C404.76 222.848 405.73 221.611 407.39 221.611C409.05 221.611 410.02 222.848 410.02 224.438C410.02 225.983 409.05 227.221 407.39 227.221C405.73 227.221 404.76 225.983 404.76 224.438ZM405.93 224.438C405.93 225.331 406.33 226.231 407.39 226.231C408.45 226.231 408.84 225.331 408.84 224.438C408.84 223.501 408.45 222.601 407.39 222.601C406.33 222.601 405.93 223.501 405.93 224.438ZM410.64 227.093V221.738H412.95C414.33 221.738 415.38 222.608 415.38 224.386C415.38 225.938 414.58 227.093 412.95 227.093H410.64ZM411.81 222.728V226.103H412.86C413.55 226.103 414.2 225.683 414.2 224.498C414.2 223.418 413.82 222.728 412.65 222.728H411.81ZM420.55 221.738V225.068C420.55 226.516 419.7 227.221 418.27 227.221C416.83 227.221 415.99 226.523 415.99 225.068V221.738H417.16V225.068C417.16 225.653 417.31 226.231 418.27 226.231C419.11 226.231 419.38 225.863 419.38 225.068V221.738H420.55ZM421.38 227.093V221.738H422.56V226.103H425.17V227.093H421.38ZM425.64 227.093V221.738H429.64V222.728H426.82V223.876H429.41V224.791H426.82V226.103H429.7V227.093H425.64Z" fill="white"/>
                <g id="module-2-map-pin" mask="url(#module-2-pin-mask)">
                    <path d="M446.61 243.085C444.45 240.546 435.96 228.088 435.96 221.471C435.96 215.688 440.73 211 446.61 211" fill="#FAB446"/>
                    <path d="M446.61 243.085C448.77 240.546 457.26 228.088 457.26 221.471C457.26 215.688 452.49 211 446.61 211" fill="#FAB446"/>
                    <path d="M446.61 228.088C450.14 228.088 453.01 225.27 453.01 221.794C453.01 218.318 450.14 215.5 446.61 215.5C443.07 215.5 440.21 218.318 440.21 221.794C440.21 225.27 443.07 228.088 446.61 228.088Z" fill="white"/>
                    <path d="M445.58 220.783H444.22C444.17 219.183 445.08 217.953 446.76 217.953C448.04 217.953 449.16 218.773 449.16 220.143C449.16 221.193 448.6 221.773 447.91 222.263C447.22 222.753 446.4 223.153 445.89 223.873H449.2V225.093H444.06C444.07 223.473 445.06 222.783 446.27 221.963C446.89 221.543 447.73 221.113 447.74 220.253C447.74 219.593 447.3 219.173 446.69 219.173C445.85 219.173 445.58 220.043 445.58 220.783Z" fill="black"/>
                </g>
                <ellipse id="module-2-map-dot" cx="447" cy="225" rx="5" ry="5" fill="#B60B31"/>
            </g>
            
            <path id="module-2-path" d="M383.69 266.724L382.58 264.683L386.81 262.4L387.91 264.44L383.69 266.724ZM392.14 262.149L391.03 260.109L395.26 257.825L396.37 259.866L392.14 262.149ZM400.6 257.575L399.49 255.534L403.72 253.251L404.83 255.291L400.6 257.575ZM409.06 253L407.95 250.96L412.18 248.676L413.28 250.717L409.06 253ZM417.51 248.425L416.4 246.385L420.63 244.102L421.74 246.142L417.51 248.425ZM425.97 243.851L424.86 241.81L429.09 239.527L430.2 241.568L425.97 243.851ZM434.43 239.276L433.32 237.236L437.54 234.953L438.65 236.993L434.43 239.276Z" fill="#C10000"/>
            

            <mask id="module-3-pin-mask" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="359" y="162" width="23" height="33">
                <path d="M381.26 162H359.958V194.085H381.26V162Z" fill="white"/>
            </mask>

            <g id="module-3-pin" @click="goToModule(3)">
                <path id="module-3-losange" d="M367.11 167.793H323.798C319.491 167.793 316 171.284 316 175.591C316 179.898 319.491 183.39 323.798 183.39H367.11C371.42 183.39 374.91 179.898 374.91 175.591C374.91 171.284 371.42 167.793 367.11 167.793Z" fill="black"/>
                <path id="module-3-label" d="M322.371 178.093V172.738H324.028L325.281 176.421H325.296L326.481 172.738H328.138V178.093H327.036V174.298H327.021L325.708 178.093H324.801L323.488 174.336H323.473V178.093H322.371ZM328.755 175.438C328.755 173.848 329.73 172.611 331.388 172.611C333.045 172.611 334.02 173.848 334.02 175.438C334.02 176.983 333.045 178.221 331.388 178.221C329.73 178.221 328.755 176.983 328.755 175.438ZM329.933 175.438C329.933 176.331 330.33 177.231 331.388 177.231C332.445 177.231 332.843 176.331 332.843 175.438C332.843 174.501 332.445 173.601 331.388 173.601C330.33 173.601 329.933 174.501 329.933 175.438ZM334.637 178.093V172.738H336.947C338.335 172.738 339.377 173.608 339.377 175.386C339.377 176.938 338.582 178.093 336.947 178.093H334.637ZM335.815 173.728V177.103H336.865C337.547 177.103 338.2 176.683 338.2 175.498C338.2 174.418 337.825 173.728 336.655 173.728H335.815ZM344.554 172.738V176.068C344.554 177.516 343.699 178.221 342.266 178.221C340.826 178.221 339.986 177.523 339.986 176.068V172.738H341.164V176.068C341.164 176.653 341.314 177.231 342.266 177.231C343.106 177.231 343.376 176.863 343.376 176.068V172.738H344.554ZM345.381 178.093V172.738H346.558V177.103H349.168V178.093H345.381ZM349.639 178.093V172.738H353.644V173.728H350.816V174.876H353.411V175.791H350.816V177.103H353.704V178.093H349.639Z" fill="white"/>
                <g id="module-3-map-pin" mask="url(#module-3-pin-mask)">
                    <path d="M370.61 194.085C368.45 191.546 359.958 179.088 359.958 172.471C359.958 166.688 364.73 162 370.61 162" fill="#FAB446"/>
                    <path d="M370.61 194.085C372.77 191.546 381.26 179.088 381.26 172.471C381.26 166.688 376.49 162 370.61 162" fill="#FAB446"/>
                    <path d="M370.61 179.088C374.14 179.088 377.01 176.27 377.01 172.794C377.01 169.318 374.14 166.5 370.61 166.5C367.07 166.5 364.21 169.318 364.21 172.794C364.21 176.27 367.07 179.088 370.61 179.088Z" fill="white"/>
                    <path d="M370.13 172.923V171.923C370.74 171.973 371.62 171.853 371.62 171.053C371.62 170.463 371.15 170.123 370.63 170.123C369.92 170.123 369.54 170.653 369.55 171.373H368.2C368.25 169.943 369.18 168.953 370.62 168.953C371.74 168.953 372.97 169.643 372.97 170.893C372.97 171.553 372.64 172.143 371.99 172.323V172.343C372.76 172.513 373.23 173.153 373.23 173.933C373.23 175.403 372 176.233 370.63 176.233C369.06 176.233 368 175.293 368.03 173.683H369.38C369.41 174.443 369.79 175.063 370.61 175.063C371.25 175.063 371.75 174.623 371.75 173.963C371.75 172.903 370.82 172.923 370.13 172.923Z" fill="black"/>
                </g>
                <ellipse id="module-3-map-dot" cx="371" cy="172" rx="5" ry="5" fill="#B60B31"/>
            </g>

            <path id="module-3-path" d="M435.76 212.605L434.47 214.538L430.48 211.867L431.76 209.933L435.76 212.605ZM427.76 207.265L426.48 209.198L422.48 206.527L423.77 204.593L427.76 207.265ZM419.77 201.925L418.48 203.858L414.49 201.186L415.77 199.253L419.77 201.925ZM411.77 196.585L410.49 198.518L406.49 195.846L407.78 193.913L411.77 196.585ZM403.78 191.245L402.49 193.178L398.5 190.506L399.78 188.573L403.78 191.245ZM395.78 185.905L394.5 187.838L390.5 185.166L391.79 183.233L395.78 185.905ZM387.79 180.565L386.5 182.498L382.51 179.826L383.79 177.893L387.79 180.565Z" fill="#C10000"/>


            <mask id="module-4-pin-mask" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="455" y="112" width="22" height="33">
                <path d="M476.4 112.907H455.1V144.992H476.4V112.907Z" fill="white"/>
            </mask>

            <g id="module-4-pin" @click="goToModule(4)">
                <path id="module-4-losange" d="M462.26 118.7H418.95C414.64 118.7 411.15 122.191 411.15 126.498C411.15 130.805 414.64 134.297 418.95 134.297H462.26C466.57 134.297 470.06 130.805 470.06 126.498C470.06 122.191 466.57 118.7 462.26 118.7Z" fill="black"/>
                <path id="module-4-label" d="M417.52 129V123.645H419.18L420.43 127.328H420.44L421.63 123.645H423.29V129H422.18V125.205H422.17L420.86 129H419.95L418.64 125.243H418.62V129H417.52ZM423.9 126.345C423.9 124.755 424.88 123.518 426.53 123.518C428.19 123.518 429.17 124.755 429.17 126.345C429.17 127.89 428.19 129.128 426.53 129.128C424.88 129.128 423.9 127.89 423.9 126.345ZM425.08 126.345C425.08 127.238 425.48 128.138 426.53 128.138C427.59 128.138 427.99 127.238 427.99 126.345C427.99 125.408 427.59 124.508 426.53 124.508C425.48 124.508 425.08 125.408 425.08 126.345ZM429.78 129V123.645H432.09C433.48 123.645 434.52 124.515 434.52 126.293C434.52 127.845 433.73 129 432.09 129H429.78ZM430.96 124.635V128.01H432.01C432.69 128.01 433.35 127.59 433.35 126.405C433.35 125.325 432.97 124.635 431.8 124.635H430.96ZM439.7 123.645V126.975C439.7 128.423 438.85 129.128 437.41 129.128C435.97 129.128 435.13 128.43 435.13 126.975V123.645H436.31V126.975C436.31 127.56 436.46 128.138 437.41 128.138C438.25 128.138 438.52 127.77 438.52 126.975V123.645H439.7ZM440.53 129V123.645H441.7V128.01H444.31V129H440.53ZM444.79 129V123.645H448.79V124.635H445.96V125.783H448.56V126.698H445.96V128.01H448.85V129H444.79Z" fill="white"/>
                <g id="module-4-map-pin" mask="url(#module-4-pin-mask)">
                    <path d="M465.75 144.991C463.59 142.452 455.1 129.995 455.1 123.378C455.1 117.595 459.87 112.907 465.75 112.907" fill="#FAB446"/>
                    <path d="M465.75 144.991C467.91 142.452 476.4 129.995 476.4 123.378C476.4 117.595 471.64 112.907 465.75 112.907" fill="#FAB446"/>
                    <path d="M465.75 129.995C469.29 129.995 472.15 127.177 472.15 123.701C472.15 120.225 469.29 117.407 465.75 117.407C462.22 117.407 459.35 120.225 459.35 123.701C459.35 127.177 462.22 129.995 465.75 129.995Z" fill="white"/>
                    <path d="M466.12 127V125.38H463.16V124.08L466.2 120H467.47V124.21H468.4V125.38H467.47V127H466.12ZM466.12 124.21V121.78H466.09L464.28 124.21H466.12Z" fill="black"/>
                </g>
                <ellipse id="module-4-map-dot" cx="475" cy="135" rx="5" ry="5" fill="#B60B31"/>
            </g>

            <path id="module-4-path" d="M383.2 168.274L382.45 166.077L387 164.533L387.75 166.73L383.2 168.274ZM392.3 165.178L391.55 162.981L396.1 161.437L396.85 163.634L392.3 165.178ZM401.4 162.082L400.65 159.885L405.2 158.34L405.95 160.537L401.4 162.082ZM410.51 158.985L409.76 156.788L414.31 155.244L415.06 157.441L410.51 158.985ZM419.61 155.889L418.86 153.692L423.41 152.148L424.16 154.345L419.61 155.889ZM428.71 152.793L427.96 150.596L432.51 149.051L433.26 151.248L428.71 152.793ZM437.81 149.696L437.06 147.499L441.61 145.955L442.36 148.152L437.81 149.696ZM446.92 146.6L446.16 144.403L450.71 142.859L451.47 145.056L446.92 146.6ZM456.02 143.504L455.27 141.307L459.82 139.762L460.57 141.959L456.02 143.504Z" fill="#C10000"/>

            <path id="summit-path" d="M466.433 110.72L464.113 110.745L464.079 105.943L466.398 105.917L466.433 110.72ZM466.348 101.104L464.027 101.13L463.993 96.3275L466.313 96.3015L466.348 101.104ZM466.271 91.4935L463.951 91.5195L463.908 86.7115L466.228 86.6856L466.271 91.4935ZM466.185 81.8784L463.866 81.9036L463.823 77.0956L466.151 77.0762L466.185 81.8784ZM466.1 72.2625L463.78 72.2884L463.746 67.4862L466.066 67.4602L466.1 72.2625ZM466.023 62.6531L463.695 62.6725L463.661 57.8703L465.98 57.8451L466.023 62.6531ZM465.938 53.0372L463.618 53.0631L463.575 48.2552L465.895 48.2292L465.938 53.0372ZM465.852 43.4221L463.533 43.4472L463.49 38.6392L465.818 38.6198L465.852 43.4221ZM465.767 33.8061L463.447 33.8321L463.413 29.0298L465.733 29.0039L465.767 33.8061Z" fill="#C10000"/>
        </svg>
    </div>
    <div class="mountain-foreground">
        <svg width="1440" height="1024" viewBox="0 0 1440 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="cloud-middle-mask" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="796" y="489" width="248" height="67">
                <path d="M1044 489.645H796V555.336H1044V489.645Z" fill="white"/>
            </mask>
            <g id="cloud-middle" mask="url(#cloud-middle-mask)">
                <path d="M1041.15 542.868C1029.89 526.616 1010.28 535.073 1010.28 535.073C1001.72 519.708 985.944 526.166 985.944 526.166C985.944 526.166 972.873 490.094 940.427 489.65C907.982 489.207 898.065 514.81 898.065 514.81C889.302 513.965 882.204 514.769 876.488 516.443C877.541 520.269 884.532 538.557 920.323 527.657C920.323 527.657 935.142 520.104 948.806 533.151C948.806 533.151 953.666 541.39 976.595 540.473C996.753 539.669 1004.19 552.13 1005.7 555.147C1028.62 555.129 1043.86 555.111 1043.86 555.111C1044.76 547.984 1041.15 542.862 1041.15 542.862V542.868Z" fill="#ECEEFB"/>
                <path d="M976.595 540.473C953.672 541.39 948.806 533.151 948.806 533.151C935.142 520.11 920.323 527.657 920.323 527.657C884.538 538.551 877.541 520.264 876.488 516.443C859.694 521.352 854.804 533.737 854.804 533.737C844.665 520.601 831.821 530.62 831.821 530.62C822.58 527.946 819.204 528.615 819.204 528.615C796.449 530.844 796 555.336 796 555.336C795.832 555.336 936.142 555.212 1005.7 555.147C1004.19 552.124 996.747 539.669 976.589 540.473H976.595Z" fill="#DFE5F8"/>
            </g>

            <g id="mountain-middle">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1235.65 1127.66L1052.8 793.124L915.492 672.219L838.112 554.667L742.323 489.645L621.15 588.907L578.491 668.771L532.259 658.504L488.986 725.255L383.426 806.839L271.505 920.934L234.515 988.21L164.11 1022.44L137.592 1066.95L48 1127.66H1235.65Z" fill="#E0E5E5"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M399.134 1127.66L409.684 1106.02L447.807 1055.82L470.837 1006.74L511.228 970.268L455.839 979.409L435.076 1019.34L355.428 1047.8L340.421 1080.92L282.236 1127.66H399.134Z" fill="#E9F1F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1235.65 1127.66L1052.8 793.124L915.492 672.219L838.112 554.667L745.023 491.457L791.351 550.703L779.312 551.821L776.958 590.635L794.32 619.699L785.068 674.463L764.305 698.436L775.21 714.997L733.071 814.259L774.691 842.713L738.307 964.229L744.071 976.825L717.545 1023.57L716.411 1047.54L692.161 1099.97L784.462 1120.59L966.788 1068.07L1213.67 1127.66H1235.65Z" fill="#84888F"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M978.913 1009.85L836.363 1013.3L893.501 963.11L908.508 915.766L914.878 876.444L965.04 846.779L959.89 786.839L933.892 778.3L911.996 748.635L821.971 728.11L906.768 722.417L930.499 741.214L922.39 701.282L880.251 672.219L850.245 669.889L884.266 660.749L915.501 672.219L1052.81 793.133L1149.3 969.667L978.922 1009.85H978.913ZM820.222 595.718L871.605 605.637L884.171 624.612L820.222 595.718Z" fill="#7E7E85"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M578.491 668.771L624.032 607.196L669.044 590.034L669.65 620.817L715.805 558.64L728.449 577.522L747.559 546.129L765.967 570.627L776.958 590.635L779.312 551.821L791.351 550.703L742.322 489.645L621.15 588.907L578.491 668.771Z" fill="#E9F1F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M793.09 626.425L716.411 656.784L681.784 709.304L606.757 737.758L547.871 846.169L516.724 884.974L518.991 919.214L546.738 875.834L602.136 834.784L636.763 780.02L700.183 745.78L727.93 705.848L764.305 698.436L785.068 674.463L793.09 626.425Z" fill="#E9F1F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M733.071 814.259L701.672 866.432L655.517 914.301L623.158 1006.74L530.857 1071.78L519.346 1097.99L562.004 1083.16H608.151L655.517 1031.84L702.806 1007.87L738.307 964.228L774.691 842.713L733.071 814.259Z" fill="#E9F1F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M519.346 766.051L455.839 793.387L407.416 888.083L310.407 1002.18L417.802 940.603L447.807 881.264L493.954 834.437L508.961 795.716L551.619 760.359L519.346 766.051Z" fill="#E9F1F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1440.01 1127.66L1334.02 1073.76L1256.64 1055.48L1186.33 963.11L1092.81 916.368L992.397 796.487L971.634 819.342L979.138 839.867L962.391 867.296L967.627 878.68L946.778 918.612L979.666 916.884L967.013 947.151L915.111 975.097L909.347 1005.28L915.631 1056.08L904.12 1085.14L897.75 1062.89L879.861 1030.38L831.967 1068.07L785.215 1082.9L758.87 1127.66H1440.01Z" fill="#3B3D3E"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1440.01 1127.66L1334.02 1073.76L1256.64 1055.48L1199.85 980.874L1211.11 1018.99L1245.13 1064.62L1283.25 1086.86L1198.37 1069.79L1236.49 1100.58L1147.07 1103.42L1071.44 1037.27L1034.54 1021.32L1060.53 1048.66L1082.66 1067.49L1031.65 1076.6L1046.06 1098.33L1021.8 1114.29L1002.17 1104.02L976.784 1088.07L964.746 1121.1L942.763 1124.55L922.788 1127.66H1440.01Z" fill="#000003"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M511.228 970.268L503.465 1003.64L519.606 1025.29L482.702 1057.2L490.734 1090.32L473.806 1127.66H399.134L409.684 1106.02L447.807 1055.82L470.837 1006.74L511.228 970.268Z" fill="#83878E"/>
            </g>

            <mask id="cloud-front-mask" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="209" y="555" width="554" height="147">
                <path d="M763 555.336H209V701.426H763V555.336Z" fill="white"/>
            </mask>
            <g id="cloud-front" mask="url(#cloud-front-mask)">
                <path d="M756.636 673.699C731.474 637.555 687.673 656.364 687.673 656.364C668.541 622.192 633.311 636.555 633.311 636.555C633.311 636.555 604.111 556.336 531.632 555.349C459.153 554.363 436.999 611.302 436.999 611.302C417.425 609.421 401.568 611.21 388.8 614.932C391.153 623.442 406.769 664.111 486.722 639.87C486.722 639.87 519.826 623.074 550.35 652.089C550.35 652.089 561.206 670.411 612.427 668.372C657.457 666.584 674.076 694.297 677.445 701.005C728.639 700.965 762.679 700.926 762.679 700.926C764.698 685.077 756.636 673.686 756.636 673.686V673.699Z" fill="#ECEEFB"/>
                <path d="M612.427 668.372C561.22 670.411 550.35 652.089 550.35 652.089C519.826 623.087 486.722 639.87 486.722 639.87C406.783 664.098 391.153 623.429 388.8 614.932C351.284 625.849 340.36 653.391 340.36 653.391C317.712 624.178 289.02 646.46 289.02 646.46C268.376 640.514 260.836 642.001 260.836 642.001C210.003 646.959 209 701.426 209 701.426C208.626 701.426 522.059 701.15 677.432 701.005C674.076 694.284 657.444 666.584 612.414 668.372H612.427Z" fill="#DFE5F8"/>
            </g>
            <path id="mountain-left-1" fill-rule="evenodd" clip-rule="evenodd" d="M387 1127.93L275.223 923.32L191.29 849.37L143.988 777.473L85.4329 737.703L11.3611 798.415L-14.7156 847.262L-42.9773 840.982L-69.4296 881.809L-133.957 931.708L-202.374 1001.49L-224.985 1042.64L-268.023 1063.58L-284.233 1090.8L-339 1127.93H387Z" fill="#E0E5E5"/>
            <path id="mountain-left-2" fill-rule="evenodd" clip-rule="evenodd" d="M689 1127.93L592.477 1078.94L522.01 1062.32L457.983 978.371L372.817 935.886L281.378 826.926L188.906 864.321L152.124 915.113L120.591 921.304L64.9015 994.986L-9.69527 1023.99L-20.2641 1046.8L-81.1947 1072.75L-143 1127.93H689Z" fill="#656A6D"/>
            <path id="mountain-right" fill-rule="evenodd" clip-rule="evenodd" d="M1562 1109.3L1490.56 978.72L1436.92 931.527L1406.69 885.642L1369.26 860.262L1321.92 899.007L1305.26 930.181L1287.19 926.174L1270.29 952.229L1229.05 984.074L1185.32 1028.61L1170.87 1054.87L1143.36 1068.23L1133 1085.6L1098 1109.3H1562Z" fill="#E0E5E5"/>
        </svg>
    </div>
</template>

<style lang="scss">
    @import "@sass-components/mountain.scss";
</style>

<script setup>
    import { gsap } from "gsap";

    const store = useTNFStore();

    const lockedTimeout = ref(null);
    const restrictedContent = ref(false);
    const restrictedContentMessage = ref('');

    const goToModule = async (module) => {
        switch(module) {
            case 1:
                if( store.progress.percentage >= 0 ) {
                    return navigateTo( "/module-1" );
                }
                break;
            case 2:
                if( store.progress.percentage >= 25 ) {
                    return navigateTo( "/module-2" );
                } else {
                    showLockedScreen(1);
                }
                break;
            case 3:
                if( store.progress.percentage >= 50 ) {
                    return navigateTo( "/module-3" );
                } else {
                    showLockedScreen(2);
                }
                break;
            case 4:
                if( store.progress.percentage >= 75 ) {
                    return navigateTo( "/module-4" );
                } else {
                    showLockedScreen(3);
                }
                break;
            default:
                return false;
        }
    }

    const hideLockedScreen = () => {

        if( document.querySelectorAll(".locked") !== undefined ) {
            gsap.set(".locked", { opacity: 1 } );
            
            var tl = gsap.timeline();
                tl.to(".locked", { duration: 1, opacity: 0, onComplete: () => {
                    restrictedContent.value = false;
                }});

            clearTimeout(lockedTimeout.value);
        }
    }

    const showLockedScreen = (module) => {
        restrictedContent.value = true;
        restrictedContentMessage.value = `Complete module ${ module } to unlock content`;

        lockedTimeout.value = setTimeout(() => {
            gsap.set(".locked", { opacity: 1 } );

            var tl = gsap.timeline();
                tl.to(".locked", { duration: 1, opacity: 0, onComplete: () => {
                    restrictedContent.value = false;
                }});
        }, 5000)
    }
</script>