import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/vercel/path0/middleware/auth.global.js";
import module_45global from "/vercel/path0/middleware/module.global.js";
import report_45global from "/vercel/path0/middleware/report.global.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  module_45global,
  report_45global
]
export const namedMiddleware = {}