export function useUserProgress() {
    const store = useTNFStore();

    const getUserProgress = async () => {
        if( store.user ) {
            try {

                if( !store.login.status ) {
                    const { data: login, error: loginError } = await useFetch( '/api/login/success', {
                        params: {
                            email: store.login.email,
                            login_id: store.login.id
                        },
                    });

                    if( login ) {
                        store.login.status = true;
                    }
    
                    if( loginError.value ) throw loginError;
                }

                const { data, error } = await useFetch( '/api/progress/get', {
                    params: {
                        user: store.user.id,
                    },
                });

                if( error.value ) throw error;

                if( data.value == undefined ) {
                    await setUserProgress();
                } else {
                    await setUserProgressState(data.value);
                }
        
            } catch (error) {
                console.error(error)
            }
        }

        return false;
    }

    const setUserProgress = async () => {
        if( store.user ) {
            store.resetUserProgress();
            try {
                const { data, error } = await useFetch( '/api/progress/upsert', {
                    params: {
                        'user'                      : store.user.id,
                        'percentage'                : store.progress.percentage,
                        'current_module'            : store.progress.current_module,
                        'module_1'                  : store.progress.module_1,
                        'module_2'                  : store.progress.module_2,
                        'module_3'                  : store.progress.module_3,
                        'module_4'                  : store.progress.module_4,
                        'completed_profile'         : store.progress.completed_profile,
                        'submitted_feedback'        : store.progress.submitted_feedback,
                        'downloaded_certificate'    : store.progress.downloaded_certificate,
                        'shared_certificate'        : store.progress.shared_certificate,
                    },
                });
        
                if( error.value ) throw error;
        
            } catch (error) {
                console.error(error)
            }
        }

        return false;
    }

    const setUserProgressState = async (data) => {
        if( store.user ) {
            const { data: profile, error } = await useFetch( '/api/profile/get', {
                params: {
                    'user': store.user.id,
                },
            });

            store.progress = {
                current_module: data.current_module,
                percentage: data.percentage,
                module_1: data.module_1,
                module_2: data.module_2,
                module_3: data.module_3,
                module_4: data.module_4,
                completed_profile: data.completed_profile,
                submitted_feedback: data.submitted_feedback,
                downloaded_certificate: data.downloaded_certificate,
                shared_certificate: data.shared_certificate,
                voucher: null
            };

            if( profile.value ) {
                store.progress.voucher = profile.value.voucher;
            }

            return true;
        }

        return false;
    }

    const getUserModule1Progress = async () => {
        if( store.user ) {
            try {
                const { data, error } = await useFetch( '/api/module-1/get', {
                    params: {
                        user: store.user.id,
                    },
                });

                if( error.value ) throw error;

                if( data.value ) {
                    store.module_1 = {
                        questions: {
                            statements: data.value.statements ? data.value.statements : { answer: [], correct: false },
                            equality: data.value.equality ? data.value.equality : { answer: [], correct: false },
                            safe_and_welcome: data.value.safe_and_welcome ? data.value.safe_and_welcome : { answer: [], correct: false },
                        },
                        reflection: {
                            inclusive: data.value.inclusive,
                            lived_experiences: data.value.lived_experiences,
                            comments: data.value.comments,
                        }
                    };
                } else {
                    store.module_1 = {
                        questions: {
                            statements: {
                                answer: [],
                                correct: false
                            },
                            equality: {
                                answer: [],
                                correct: false
                            },
                            safe_and_welcome: {
                                answer: [],
                                correct: false
                            }
                        },
                        reflection: {
                            inclusive: null,
                            lived_experiences: null,
                            comments: null,
                        }
                    };
                }
        
            } catch (error) {
                console.error(error)
            }

            return true;
        }

        return false;
    }

    const getUserModule2Progress = async () => {
        if( store.user ) {
            try {
                const { data, error } = await useFetch( '/api/module-2/get', {
                    params: {
                        user: store.user.id,
                    },
                });

                if( error.value ) throw error;

                if( data.value ) {
                    store.module_2 = {
                        questions: {
                            etb_model: data.value.etb_model ? data.value.etb_model : { answer: [], correct: false },
                            lived_experiences: data.value.lived_experiences ? data.value.lived_experiences : { answer: [], correct: false },
                        },
                        reflection: {
                            sense_of_belonging: data.value.sense_of_belonging,
                            excluded_left_out: data.value.excluded_left_out,
                            your_role: data.value.your_role,
                            comments: data.value.comments,
                        }
                    };
                } else {
                    store.module_2 = {
                        questions: {
                            etb_model: {
                                answer: [],
                                correct: false
                            },
                            lived_experiences: {
                                answer: [],
                                correct: false
                            }
                        },
                        reflection: {
                            sense_of_belonging: null,
                            excluded_left_out: null,
                            your_role: null,
                            comments: null,
                        }
                    };
                }
        
            } catch (error) {
                console.error(error)
            }

            return true;
        }

        return false;
    }

    const getUserModule3Progress = async () => {
        if( store.user ) {
            try {
                const { data, error } = await useFetch( '/api/module-3/get', {
                    params: {
                        user: store.user.id,
                    },
                });

                if( error.value ) throw error;

                if( data.value ) {
                    store.module_3 = {
                        questions: {
                            scenario_1: data.value.scenario_1 ? data.value.scenario_1 : { answer: [], correct: false },
                            scenario_2: data.value.scenario_2 ? data.value.scenario_2 : { answer: [], correct: false },
                            scenario_3: data.value.scenario_3 ? data.value.scenario_3 : { answer: [], correct: false },
                            scenario_4: data.value.scenario_4 ? data.value.scenario_4 : { answer: [], correct: false },
                            scenario_5: data.value.scenario_5 ? data.value.scenario_5 : { answer: [], correct: false },
                            internalised_racism: data.value.internalised_racism ? data.value.internalised_racism : { answer: [], correct: false },
                            level_of_racism: data.value.level_of_racism ? data.value.level_of_racism : { answer: [], correct: false },
                        },
                        self_assessment: {
                            personal_feelings: data.value.personal_feelings ? data.value.personal_feelings : [],
                        }
                    };
                } else {
                    store.module_3 = {
                        questions: {
                            scenario_1: {
                                answer: [],
                                correct: false
                            },
                            scenario_2: {
                                answer: [],
                                correct: false
                            },
                            scenario_3: {
                                answer: [],
                                correct: false
                            },
                            scenario_4: {
                                answer: [],
                                correct: false
                            },
                            scenario_5: {
                                answer: [],
                                correct: false
                            },
                            internalised_racism: {
                                answer: [],
                                correct: false
                            },
                            level_of_racism: {
                                answer: [],
                                correct: false
                            }
                        },
                        self_assessment: {
                            personal_feelings: []
                        }
                    };
                }
        
            } catch (error) {
                console.error(error)
            }

            return true;
        }

        return false;
    }

    const getUserModule4Progress = async () => {
        if( store.user ) {
            try {
                const { data, error } = await useFetch( '/api/module-4/get', {
                    params: {
                        user: store.user.id,
                    },
                });

                if( error.value ) throw error;

                if( data.value ) {
                    store.module_4 = {
                        questions: {
                            personal_or_systemic: { answer: JSON.parse(data.value.personal_or_systemic.answer), correct: data.value.personal_or_systemic.correct },
                            what_is_allyship: data.value.what_is_allyship ? data.value.what_is_allyship : { answer: [], correct: false },
                            allyship_become_harmful: data.value.allyship_become_harmful ? data.value.allyship_become_harmful : { answer: [], correct: false },
                        },
                        pledge: {
                            your_pledge: data.value.your_pledge
                        }
                    };

                    if( store.module_4.questions.personal_or_systemic == null ) {
                        store.module_4.questions.personal_or_systemic = {
                            answer: {
                                check_your_privilege: null,
                                upstander: null,
                                amplify_voices: null,
                                use_language: null,
                                stay_curious: null,
                                ally_networks: null,
                                upskill_communities: null,
                                challenge_narrative: null,
                                sponsor_leaders: null,
                                check_your_privilege: null
                            },
                            correct: false
                        };
                    }
                } else {
                    store.module_4 = {
                        questions: {
                            personal_or_systemic: {
                                answer: {
                                    check_your_privilege: null,
                                    upstander: null,
                                    amplify_voices: null,
                                    use_language: null,
                                    stay_curious: null,
                                    ally_networks: null,
                                    upskill_communities: null,
                                    challenge_narrative: null,
                                    sponsor_leaders: null,
                                    check_your_privilege: null
                                },
                                correct: false
                            },
                            what_is_allyship: {
                                answer: [],
                                correct: false
                            },
                            allyship_become_harmful: {
                                answer: [],
                                correct: false
                            }
                        },
                        pledge: {
                            your_pledge: null,
                        }
                    };
                }
        
            } catch (error) {
                console.error(error)
            }

            return true;
        }

        return false;
    }

    return {
        getUserProgress,
        getUserModule1Progress,
        getUserModule2Progress,
        getUserModule3Progress,
        getUserModule4Progress
    };
}
  

